document.addEventListener("alpine:init", () => {
  window.Alpine.data("cart", () => ({
    init() {
      this.bottomBar = this.$refs.bottomBar;

      document
        .querySelectorAll(
          '.woocommerce-cart-form [x-data="quantityInput"] select',
        )
        .forEach((select) => {
          select.addEventListener("change", this.updateCart);
        });

      document
        .querySelectorAll(
          '.woocommerce-cart-form [x-data="quantityInput"] input',
        )
        .forEach((input) => {
          input.addEventListener("blur", this.updateCart);
        });
    },

    updateCart() {
      let button = document.querySelector('button[name="update_cart"]');

      if (!button) return;

      button.removeAttribute("disabled");
      button.click();
    },

    showBottomBar() {
      this.bottomBar.classList.remove("hidden");
    },

    hideBottomBar() {
      this.bottomBar.classList.add("hidden");
    },
  }));
});
