document.addEventListener("alpine:init", () => {
  window.Alpine.data("checkout", () => ({
    init() {
      window.addEventListener("load", function () {
        document
          .querySelectorAll(
            ".validate-required input, .validate-required select",
          )
          .forEach((element) => {
            if (element.value) {
              element.dispatchEvent(new Event("validate", { bubbles: true }));
            }
          });

        let shippingCountryField = document.getElementById(
          "shipping_country_field",
        );
        if (
          shippingCountryField &&
          !shippingCountryField.classList.contains("woocommerce-validated")
        ) {
          shippingCountryField.classList.add("woocommerce-validated");
        }

        let billingCountryField = document.getElementById(
          "billing_country_field",
        );
        if (
          billingCountryField &&
          !billingCountryField.classList.contains("woocommerce-validated")
        ) {
          billingCountryField.classList.add("woocommerce-validated");
        }
      });
    },
  }));
});
