document.addEventListener("alpine:init", () => {
  window.Alpine.data("productFinder", () => ({
    brand: null,
    brandInput: null,
    model: null,
    models: [],
    slug: null,

    init() {
      this.$watch("brand", () => {
        let brandDataset =
          this.$refs.brandInput.options[this.$refs.brandInput.selectedIndex]
            .dataset;

        if (!brandDataset.models) {
          this.models = [];
          this.slug = null;
          return;
        }

        this.models = JSON.parse(brandDataset.models);
        this.slug = null;
      });

      this.$watch("model", () => {
        if (!this.model) {
          this.slug = null;
          return;
        }

        this.slug = "/printer/" + this.brand + "/" + this.model;
      });
    },
  }));
});
