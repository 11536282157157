document.addEventListener("alpine:init", () => {
  window.Alpine.data("primaryMenu", () => ({
    open: false,
    activeNodeId: null,

    togglePrimaryMenu() {
      this.open = !this.open;

      if (!this.open) {
        document.body.classList.remove(
          "overflow-y-scroll",
          "fixed",
          "w-full",
          "!mt-0",
        );
        this.activeNodeId = null;
      } else {
        document.body.classList.add(
          "overflow-y-scroll",
          "fixed",
          "w-full",
          "!mt-0",
        );
      }
    },

    setActiveNodeId($event, id, hasChildren) {
      if (window.innerWidth >= 1024 || !hasChildren) {
        return;
      }

      $event.preventDefault();
      this.activeNodeId = id;
    },

    init() {
      if (
        window.innerWidth >= 1024 &&
        document.querySelector("#primary-menu > ul")
      ) {
        let primaryMenu = document.querySelector("#primary-menu > ul");
        let primaryMenuItems = primaryMenu.querySelectorAll(
          "li.group:not(#primary-menu-overflow)",
        );
        let primaryMenuOverflow = primaryMenu.querySelector(
          "#primary-menu-overflow",
        );
        let primaryMenuOverflowItems =
          primaryMenuOverflow.querySelectorAll("ul > li");
        let primaryMenuWidth = null;

        new ResizeObserver(function (entries) {
          let menu = entries[0];
          if (!menu.borderBoxSize) {
            return;
          }

          let borderBoxSize = menu.borderBoxSize[0].inlineSize ?? null;
          if (borderBoxSize === null || borderBoxSize === primaryMenuWidth) {
            return;
          }

          primaryMenuWidth = borderBoxSize;

          let primaryMenuItemsWidth = 0;
          let primaryMenuItemsShown = 0;
          primaryMenuItems.forEach(function (menuItem) {
            menuItem.classList.remove("lg:hidden");
            primaryMenuItemsWidth += menuItem.clientWidth;

            if (
              primaryMenuItemsWidth + 15 * (primaryMenuItemsShown + 1) >
              primaryMenuWidth
            ) {
              menuItem.classList.add("lg:hidden");
            } else {
              primaryMenuItemsShown++;
            }
          });

          if (primaryMenuItemsShown < primaryMenuItems.length) {
            primaryMenuItems[primaryMenuItemsShown - 1]?.classList?.add(
              "lg:hidden",
            );
            primaryMenuOverflow.classList.remove("lg:!hidden");
          } else {
            primaryMenuOverflow.classList.add("lg:!hidden");
          }

          primaryMenuOverflowItems.forEach(function (menuItem, index) {
            if (index >= primaryMenuItemsShown - 1) {
              menuItem.classList.remove("lg:hidden");
            } else {
              menuItem.classList.add("lg:hidden");
            }
          });
        }).observe(primaryMenu);
      }
    },
  }));
});
